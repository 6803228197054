import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,8,2,3,5,7];

export const dictionary = {
		"/": [9],
		"/account": [~10],
		"/account/update_password": [~11],
		"/auth": [12],
		"/auth/reset_password": [13],
		"/auth/sign_in": [14],
		"/auth/sign_up": [15],
		"/teams": [40,[8]],
		"/teams/[team_id]": [~41,[8]],
		"/t/[team_id]/bots": [~16],
		"/t/[team_id]/bots/new": [26],
		"/t/[team_id]/bots/[bot_id]/config": [17,[2]],
		"/t/[team_id]/bots/[bot_id]/connections": [18,[2,3]],
		"/t/[team_id]/bots/[bot_id]/connections/discord": [19,[2,3,4]],
		"/t/[team_id]/bots/[bot_id]/connections/discord/[conn_id]/conn": [~20,[2,3,4,5]],
		"/t/[team_id]/bots/[bot_id]/connections/discord/[conn_id]/servers": [~21,[2,3,4,5]],
		"/t/[team_id]/bots/[bot_id]/connections/whatsapp": [22,[2,3,6]],
		"/t/[team_id]/bots/[bot_id]/connections/whatsapp/[conn_id]": [23,[2,3,6,7]],
		"/t/[team_id]/bots/[bot_id]/knowledge": [~24,[2]],
		"/t/[team_id]/bots/[bot_id]/link_knowledge_source": [~25,[2]],
		"/t/[team_id]/conversations": [~27],
		"/t/[team_id]/conversations/bots/[bot_id]": [~29],
		"/t/[team_id]/conversations/[conversation_id]/chat": [~28],
		"/t/[team_id]/knowledge_sources": [~30],
		"/t/[team_id]/knowledge_sources/new": [34],
		"/t/[team_id]/knowledge_sources/new/document": [35],
		"/t/[team_id]/knowledge_sources/new/faq": [36],
		"/t/[team_id]/knowledge_sources/new/text": [37],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]": [~31],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]/edit": [~32],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]/faq": [~33],
		"/t/[team_id]/tags": [38],
		"/t/[team_id]/tags/training": [~39]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';